
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.inputFieldControl {
  label {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.015em;
    color: color.$black-color;
    font-weight: 700;
    span {
      color: color.$black-color;
      font-size: 9px;
    }
  }

  input {
    border-radius: 4px;
    border: 1px solid color.$light-gray;
    height: 42px;
    padding: 10px 16px;
    font-size: 1.125rem;
    font-weight: typography.$light;
    line-height: 1.3333333;
    color: color.$grey10;
    background-color: color.$white-color;
    transition:
      border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    &:focus {
      border-color: color.$light-blue;
      color: color.$grey10;
      outline: 0;
      -webkit-box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(102, 175, 233, 0.6);
      box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(102, 175, 233, 0.6);
    }
  }

  p {
    color: color.$red;
    display: none;
    margin-top: 5px;
    margin-bottom: 10px;
  }
}

.inputFieldControl.error {
  input {
    border-color: color.$red;
    &:focus {
      border-color: color.$red;
      -webkit-box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 6px #ce8483;
      box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 6px #ce8483;
    }
  }
  p {
    color: color.$red;
    display: block;
  }
}

.inputFieldControl.large {
  input {
    height: 46px;
  }
}

.inputFieldControl.round {
  input {
    border-radius: 20px;
    padding: 6px 65px 6px 18px;
    background-color: color.$white-color;
  }
}
