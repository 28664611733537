
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.btn {
  padding: 5px 22px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 50px;
  border: 2px solid transparent;
  transition: 0.2s ease-in-out;
  min-width: 86px;
  text-align: center;
  text-decoration: none;
  font-weight: typography.$medium;
  &:hover {
    transform: scale(1.04, 1.04);
  }
}

.btn-full {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  display: block;
}

.btn-lg {
  padding: 7px 32px;
  font-size: 16px;
  line-height: 26px;
}

.btn-sm {
  padding: 3px 18px;
  font-size: 12px;
  line-height: 18px;
}

.danger {
  color: color.$white-color;
  background-color: color.$danger-btn-color;
  border-color: color.$danger-btn-color;
  &:hover {
    background-color: color.$danger-btn-hover-color;
    border-color: color.$danger-btn-hover-color;
    color: color.$white-color;
  }
  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.primary {
  color: color.$white-color;
  background-color: color.$primary-color;
  border-color: color.$primary-color;
  &:hover {
    background-color: color.$primary-hover-color;
    border-color: color.$primary-hover-color;
    color: color.$white-color;
  }
  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.secondary {
  color: color.$primary-color;
  background-color: color.$white-color;
  border-color: color.$primary-color;
  &:hover {
    color: color.$white-color;
    background-color: color.$primary-hover-color;
    border-color: color.$primary-hover-color;
  }
  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.ternary {
  color: color.$white-color;
  background-color: color.$ternary-btn-color;
  border-color: color.$ternary-btn-color;
  &:hover {
    background-color: color.$ternary-btn-hover-color;
    border-color: color.$ternary-btn-hover-color;
    color: color.$white-color;
  }
  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.transparent {
  color: color.$white-color;
  background-color: transparent;
  border-color: color.$white-color;
}
